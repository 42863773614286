import { Link } from "gatsby"
import React from 'react'

import { Content } from '@components/Content'
import { Seo } from '@components/Seo'

const NotFoundPage = () => (
  <>
    <Seo title="404: Page not found" description="Unfortunately, we could not find the page you were looking for" />
    <Content textblock={true}>
      <section className="item">
        <h1>Page not found</h1>
        <p>
          Unfortunately, we could not find the page you were looking for. If you were trying to redeem a download code,
          please continue to our <Link to="https://nutriot.bandcamp.com/yum">Bandcamp</Link> page.
        </p>
        <p>
          <Link href="/">Return to home page</Link>.
        </p>
      </section>
    </Content>
  </>
)

export default NotFoundPage
